/* App.css */

body {
  font-family: 'Arial', sans-serif;
  margin: 0;
  padding: 0;
  background-color: #f4f4f4;
  color: #333;
}

.App {
  text-align: center;
  padding: 20px;
}

.header {
  background-color: #4285f4;
  color: #ffffff;
  padding: 10px;
}

.header h1 {
  margin: 0;
}

nav {
  margin-top: 20px;
}

nav a {
  margin-right: 15px;
  text-decoration: none;
  color: #333;
  font-weight: bold;
}

nav a:hover {
  color: #4285f4;
}

.container {
  max-width: 800px;
  margin: 20px auto;
  background-color: #ffffff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.footer {
  background-color: #4285f4;
  color: #ffffff;
  padding: 10px;
  position: fixed;
  bottom: 0;
  width: 100%;
}

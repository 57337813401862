/* Colors */
:root {
  --green-seaweed: rgba(2, 128, 144, 1);
  --blue-queen: rgba(69, 105, 144, 1);
  --red-fire: rgba(244, 91, 105, 1);
  --font-asap: 'Asap', sans-serif;
  scroll-behavior: smooth;
}

/* Global Styles */
body {
  background-color: var(--red-fire);
  font-family: var(--font-asap);
  color: #444444;
}

a {
  color: #8ec454;
  text-decoration: none;
}

a:hover {
  color: #b2ce9d;
  text-decoration: none;
}

h1, h2, h3, h4, h5, h6 {
  font-family: "Nunito", sans-serif;
}

/* Main Content */
#main {
  margin-top: 60px;
  padding: 20px 30px;
  transition: all 0.3s;
  margin-left: 300px; /* Ensure space for sidebar */
  visibility: visible; /* Ensure content is visible */
}

@media (max-width: 1199px) {
  #main {
    padding: 20px;
    margin-left: 0;
  }
}

/* Sidebar */
.sidebar {
  position: fixed;
  top: 60px;
  left: 0;
  bottom: 0;
  width: 300px;
  z-index: 996;
  padding: 20px;
  overflow-y: auto;
  scrollbar-width: thin;
  scrollbar-color: #aab7cf transparent;
  box-shadow: 0px 0px 20px rgba(35, 104, 41, 0.1);
  background-color: #fff;
  transition: all 0.3s;
}

@media (max-width: 1199px) {
  .sidebar {
    left: -300px;
  }
  .toggle-sidebar .sidebar {
    left: 0;
  }
}

.sidebar-nav {
  padding: 0;
  list-style: none;
}

.sidebar-nav .nav-item {
  margin-bottom: 5px;
}

.sidebar-nav .nav-heading {
  font-size: 11px;
  text-transform: uppercase;
  color: #000000;
  font-weight: 600;
  margin: 10px 0 5px 15px;
}

.sidebar-nav .nav-link {
  display: flex;
  align-items: center;
  font-size: 15px;
  font-weight: 600;
  color: #1d3045;
  background: #f6f9ff;
  padding: 10px 15px;
  border-radius: 4px;
  transition: 0.3s;
}

.sidebar-nav .nav-link i {
  font-size: 16px;
  margin-right: 10px;
}

.sidebar-nav .nav-link:hover {
  color: #1d3045;
  background: #f6f9ff;
}

/* Ensure the main content is visible */
body.toggle-sidebar #main {
  margin-left: 0;
}

/* Ensure background image is displayed properly */
.background-image {
  background-image: url('/src/img/hol7.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  min-height: 100vh; /* Ensure it covers the full screen */
  display: block; /* Make sure the image is visible */
}

/* Login Section */
.login {
  overflow: hidden;
  background-color: white;
  padding: 40px 30px;
  border-radius: 10px;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 400px;
  transform: translate(-50%, -50%);
  transition: transform 300ms, box-shadow 300ms;
  box-shadow: 5px 10px 10px rgba(26, 110, 51, 0.856);
}

.login::before,
.login::after {
  content: 'TNS';
  position: absolute;
  width: 600px;
  height: 600px;
  border-top-left-radius: 40%;
  border-top-right-radius: 45%;
  border-bottom-left-radius: 35%;
  border-bottom-right-radius: 40%;
  z-index: -1;
}

.login::before {
  left: 47%;
  bottom: -73%;
  background-color: rgb(23, 212, 80);
  animation: waves 6s infinite linear;
}

.login::after {
  left: 35%;
  bottom: -90%;
  background-color: rgba(26, 110, 51, 0.856);
  animation: waves 7s infinite;
}

.login input {
  display: block;
  width: 100%;
  border-radius: 7px;
  font-size: 16px;
  background: white;
  padding: 10px;
  margin: 15px 0;
  border: 10;
}

.login button {
  cursor: pointer;
  color: #fff;
  font-size: 16px;
  text-transform: uppercase;
  width: 80px;
  border: 0;
  padding: 10px 0;
  margin-top: 10px;
  border-radius: 5px;
  background-color: rgb(21, 155, 61);
  transition: background-color 200ms;
}

.login button:hover {
  background-color: rgb(21, 57, 155);
}

/* Fix any issues where main content is hidden */
.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.loading-gif {
  width: 260px;
  height: 180px;
}

@keyframes waves {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Header styles */
.header {
  background-color: #fff; /* Set a white background to remove any unwanted color */
  box-shadow: 0 2px 20px rgba(22, 253, 2, 0.1); /* Add a subtle shadow */
  height: 60px;
  z-index: 997;
}

/* Sidebar toggle button */
.toggle-sidebar-btn {
  font-size: 24px;
  color: #000; /* Make sure the button is visible */
  cursor: pointer;
  padding-right: 20px; /* Space between the button and the edge */
}

/* Logo styling */
.logo {
  display: flex;
  align-items: center;
}

.logo-img {
  width: 150px; /* Adjust the width of the logo */
  height: auto;
  margin-right: 10px; /* Space between the logo and the text */
}

/* When the sidebar is collapsed, make sure the logo shrinks */
.toggle-sidebar .logo-img {
  width: 80px; /* Adjust for a smaller logo when the sidebar is hidden */
}

/* Sidebar transition */
.sidebar {
  transition: all 0.3s ease-in-out;
}

.toggle-sidebar .sidebar {
  left: -300px; /* Hide the sidebar when toggled */
}

.custom-input {
  display: block;
  width: 100%;
  padding: 12px 20px;
  margin: 8px 0;
  box-sizing: border-box;
  border: 2px solid #aaa; /* Visible border */
  border-radius: 4px;
  font-size: 16px;
  background-color: white;
  outline: none;
}

/* Change border color when focused */
.custom-input:focus {
  border-color: #2196f3;
  box-shadow: 0 0 5px rgba(33, 150, 243, 0.5);
}

/* Optional hover effect */
.custom-input:hover {
  border-color: #777;
}


